
import { FC } from 'react';

const AboutUs: FC = () => {
    return (
        <section className="bg-[#122417] text-white py-16" id="about-us">
            <div className="text-center mb-14">
                <a href="/about">
                    <span className="text-4xl font-semibold text-[#f1c40f] hover:underline">Hakkımızda</span>
                </a>
            </div>
            <div className="container mx-auto px-16">
                <div className="flex flex-col md:flex-row justify-between items-center">
                    <div className="text-center mb-8 md:mb-0">
                        <p className="text-lg mb-6">
                        Gürbüz&Aydemir Hukuk ve Danışmanlık; ulusal ve uluslararası alanda hukuki hizmet veren bir hukuk bürosudur.Hukuk büromuz yerli ve yabancı müvekkillerine en iyi şekilde hizmet sunmayı hedeflemektedir. 
                        Şirketler Hukuku, İş Hukuku, Aile Hukuku, Sigorta Hukuku, Sözleşmeler Hukuku, Miras Hukuku, Ceza Hukuku, Kira Hukuku, İcra ve İflas Hukuku çalışma alanlarımızın önemli bir bölümünü oluşturmakta olup şirketlere ve şahıslara hukukun her alanında danışmanlık ve avukatlık hizmeti sağlamaktayız. 
                        Genç, dinamik ve alanında uzman çalışma ekibimizle birlikte müvekkillerimizle yakın ilişki içinde çalışarak, ihtiyaçlarına göre ayrıcalıklı, çözüm odaklı ve kaliteli hukuki hizmetler sunmaktayız.
                        </p>
                        <p className="text-lg">
                            Şeffaf ve hızlı hukuki danışmanlık ile müvekkillerimizin dava süreçlerinin ve hukuki süreçlerinin takibini yaparken kurumsal ve etkin bir disiplin içinde çalışıyoruz.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
