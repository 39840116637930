// src/components/TeamsSection.tsx
import { FC } from 'react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';

const teamMembers = [
    {
        name: "Av. Mustafa Gürbüz",
        title: "Kurucu Ortak",
        image: "/assets/logo.png",
        instagram: "https://www.instagram.com/av.mustafagurbuz?igsh=YThjeWN2bmlrOTl2&utm_source=qr",
        linkedin: "https://www.linkedin.com/in/mustafagurbuzmg?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
        name: "Av. Ahmet Aydemir",
        title: "Kurucu Ortak",
        image: "/assets/logo.png",
        instagram: "https://www.instagram.com/av.ahmetaydemir?igsh=MWRna3g3bXN6ZWZyYg==",
        linkedin: "https://www.linkedin.com/in/ahmet-aydemir01?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    }
];

const TeamsSection: FC = () => {
    return (
        <section className="bg-[#122417] text-white py-16" id="team">
            <div className="container mx-auto px-4">
                <h2 className="text-center text-4xl text-[#f1c40f] font-bold mb-12">Ekibimiz</h2>
                <div className="flex flex-wrap justify-center">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="w-full sm:w-1/2 lg:w-1/4 p-4">
                            <div className="relative group overflow-hidden rounded-lg shadow-lg">
                                <img
                                    src={member.image}
                                    alt={member.name}
                                    width={680}
                                    height={680}
                                    className="w-full object-cover h-[680px]"
                                />
                                <div className="absolute inset-1 bg-black bg-opacity-30 lg:opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-row justify-end p-4">
                                    <div className="text-center mt-2 flex justify-between items-center mx-auto">
                                        <a href={member.instagram} target="_blank" rel="noopener noreferrer" className="text-white text-4xl mr-4 hover:text-gray-300 z-10">
                                            <FaInstagram />
                                        </a>
                                        <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="text-white text-4xl hover:text-gray-300 z-10">
                                            <FaLinkedin />
                                        </a>
                                    </div>
                                </div>
                                <div className="absolute inset-0 flex flex-col justify-end p-4 bg-black bg-opacity-25 md:bg-opacity-0 md:group-hover:bg-opacity-50 md:opacity-100 transition-opacity duration-300">
                                    <h3 className="text-xl font-bold">{member.name}</h3>
                                    <p>{member.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TeamsSection;
