"use client"
// src/components/PracticeAreasGrid.tsx

import { FC } from 'react';

const PracticeAreasGrid: FC = () => {
    const areas = [
        {
            title: "Şirketler Hukuku",
            description: "",
            image: "/assets/practice-area/sirket.jpg",
        },
        {
            title: "İş Hukuku",
            description: "",
            image: "/assets/practice-area/is.jpg",
        },
        {
            title: "Aile Hukuku",
            description: "",
            image: "/assets/practice-area/aile.jpg",
        },
        {
            title: "Sigorta Hukuku",
            description: "",
            image: "/assets/practice-area/sigorta.jpg",
        },
        {
            title: "Miras Hukuku",
            description: "",
            image: "/assets/practice-area/miras.jpg",
        },
        {
            title: "Ceza Hukuku",
            description: "",
            image: "/assets/practice-area/ceza.jpg",
        },
    ];

    return (
        <section className="bg-[#122417] text-white w-full py-16" id="practice-area">
            <div className="text-center mb-14">
                <a href="/about" >
                    <span className="text-4xl font-semibold text-[#f1c40f] hover:underline">Çalışma Alanlarımız</span>
                </a>
            </div>
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {areas.map((area, index) => (
                        <div key={index} className="relative overflow-hidden rounded-lg shadow-lg">
                            <img
                                src={area.image}
                                alt={area.title}
                                width={500}
                                height={300}
                                className="object-cover w-full h-[300px] lg:h-[400px]"
                            />
                            <div className="absolute inset-0 bg-black bg-opacity-50 lg:opacity-0 lg:hover:opacity-100 transition-opacity duration-300 flex flex-col justify-end p-4">
                                <h3 className="text-sm lg:text-xl font-bold text-wrap">{area.title}</h3>
                                <p>{area.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PracticeAreasGrid;
