"use client"

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Hero = () => {
    const slides = [
        {
            image: "/assets/hero-bg-1.jpg",
            title: "",
            description: "",
        },
        {
            image: "/assets/hero-bg-2.jpg",
            title: "",
            description: "",
        },
        {
            image: "/assets/hero-bg-3.jpg",
            title: "Uzman kadromuz ve güçlü çözüm ortaklarımız ile profesyonel hukuk ve danışmanlık hizmeti sunuyoruz.",
            description: "",
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
        cssEase: 'linear',
        arrows: false,
    };

    return (
        <section className="h-screen overflow-hidden !w-full -mt-32" id="hero">
            <Slider {...settings} className="h-screen z-0">
                {slides.map((slide, index) => (
                    <div key={index} className="relative h-screen w-screen">
                        <div className="absolute inset-0">
                            <img
                                src={slide.image}
                                alt={`Slide ${index + 1}`}
                                className="object-cover h-screen w-full"
                            />
                            <div className="absolute inset-0 bg-black opacity-50"></div>
                        </div>
                        <div className="absolute inset-0 flex items-center justify-center z-10">
                            <div className={`text-center px-4 ${index === 0 ? "w-full" : "max-w-4xl"}`}>
                                {index === 0 &&
                                    <div>
                                        <h1 className="mb-6 lg:ml-40 !w-full !text-left text-[#f1c40f] lg:mb-8  text-6xl lg:text-8xl font-extrabold">
                                            Etkin
                                        </h1>
                                        <h1 className="mb-6 lg:ml-40 !w-full !text-left text-[#f1c40f] lg:mb-8  text-6xl lg:text-8xl font-extrabold">
                                            Profesyonel
                                        </h1>
                                        <h1 className="mb-6 lg:ml-40 !w-full !text-left text-[#f1c40f] lg:mb-8  text-6xl lg:text-8xl font-extrabold">
                                            Şeffaf
                                        </h1>
                                    </div>
                                }
                                {index === 1 && <div className="flex justify-center lg:mt-12">
                                    <img src="/assets/logo.png" alt="gurbuz-aydemir" className="w-[650px] h-[670px] object-contain" />
                                </div>}
                                <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-[#f1c40f]">
                                    {slide.title}
                                </h1>
                                <p className="mb-6 text-white lg:mb-8 md:text-lg lg:text-xl">
                                    {slide.description}
                                </p>
                                {/* <div className="flex flex-col sm:flex-row justify-center gap-4">
                                    <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">
                                        Get started
                                        <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </a>
                                    <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-[#f1c40f] border border-gray-300 rounded-lg hover:bg-gray-600 focus:ring-4 focus:ring-gray-100">
                                        Speak to Sales
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </section>
    );
};

export default Hero;