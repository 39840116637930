import AboutUs from "./components/Home/AboutUs";
import ContactUsSection from "./components/Home/Contact";
import Hero from "./components/Home/Hero";
import PracticeAreasCarousel from "./components/Home/PracticeAreasCarousel";
import TeamsSection from "./components/Home/Teams";
import Footer from "./components/nav/Footer";
import Navbar from "./components/nav/Navbar";


export default function Home() {
  return (
    <main className="scroll-smooth">
      <Navbar />
      <section className="mt-28">
        <div className="space-y-20 w-full h-full scroll-smooth">
          <Hero />
          <AboutUs />
          <PracticeAreasCarousel />
          {/* <AboutUsSecondSection />
          <StatisticsSection /> */}
          <TeamsSection />
          <ContactUsSection />
        </div>
      </section>
      <Footer />
    </main>
  );
}
