// src/components/ContactUsSection.tsx
import { FC } from 'react';

const ContactUsSection: FC = () => {
    return (
        <section className="bg-[#122417] text-white py-16" id="contact">
            <div className="container mx-auto px-4 flex flex-col md:flex-row">
                <div className="md:w-1/2 mb-8 md:mb-0">
                    <h2 className="text-3xl font-bold mb-4">İletişim</h2>
                    <div className="space-y-4">
                        <div className="flex items-center space-x-4">
                            <div className="text-2xl"><i className="fas fa-home"></i></div>
                            <div>
                                <h3 className="text-xl font-semibold">Adres</h3>
                                <p>19 Mayıs Mah. Şemsettin Günaltay Cad. No:152/15 Kadıköy/İstanbul</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            <div className="text-2xl"><i className="fas fa-envelope"></i></div>
                            <div>
                                <h3 className="text-xl font-semibold">E-mail</h3>
                                <p>info@gurbuzaydemir.av.tr</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            <div className="text-2xl"><i className="fas fa-phone"></i></div>
                            <div>
                                <h3 className="text-xl font-semibold">Telefon</h3>
                                <p>+90 554 902 78 44</p>
                                <p>+90 545 630 82 17</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            <div className="text-2xl"><i className="fas fa-clock"></i></div>
                            <div>
                                <h3 className="text-xl font-semibold">Çalışma Saatlerimiz</h3>
                                <p>Pazartesi - Cuma: 09:00 - 19:00</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.6029070411046!2d29.083370576423338!3d40.96827712188644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac7a9681cb519%3A0xab67c4983095a048!2zS296eWF0YcSfxLEsIMWeZW1zZXR0aW4gR8O8bmFsdGF5IENkLiBObzoxNTIsIDM0NzM2IEthZMSxa8O2eS_EsHN0YW5idWw!5e0!3m2!1str!2str!4v1723589903621!5m2!1str!2str"
                        width="100%"
                        height="400"
                        allowFullScreen={false}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="addresses"></iframe>
                </div>
                {/* <div className="md:w-1/2 bg-[#c5a97f] p-8 rounded-lg">
                    <h2 className="text-3xl font-bold text-[#0f2139] mb-4">Free Consulting</h2>
                    <form className="space-y-4">
                        <div>
                            <label className="block text-lg text-[#0f2139]">Your Name</label>
                            <input type="text" className="w-full p-2 rounded border border-[#0f2139]" />
                        </div>
                        <div>
                            <label className="block text-lg text-[#0f2139]">Your Email</label>
                            <input type="email" className="w-full p-2 rounded border border-[#0f2139]" />
                        </div>
                        <div>
                            <label className="block text-lg text-[#0f2139]">Your Phone</label>
                            <input type="text" className="w-full p-2 rounded border border-[#0f2139]" />
                        </div>
                        <div>
                            <label className="block text-lg text-[#0f2139]">Service</label>
                            <select className="w-full p-2 rounded border border-[#0f2139] bg-slate-700">
                                <option>Family Law</option>
                                <option>Criminal Law</option>
                                <option>Personal Injury</option>
                                <option>Real Estate Law</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-lg text-[#0f2139]">Case Description</label>
                            <textarea className="w-full p-2 rounded border border-[#0f2139]" rows={4}></textarea>
                        </div>
                        <button type="submit" className="w-full p-3 rounded bg-[#0f2139] text-white font-bold">Submit Now</button>
                    </form>
                </div> */}
            </div>
            {/* <div className="">
                <GoogleMapComponent />
            </div> */}
        </section>
    );
};

export default ContactUsSection;
