"use client"

import { FC, useEffect, useState } from 'react';
import NavLink from '../UI/NavLink';

const Navbar: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 10;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    return (
        <nav className={`fixed top-0 w-full z-50 transition-all bg-opacity-65 duration-300 ${scrolled ? 'bg-[#122417] py-2' : 'bg-transparent py-2'
            }`}>
            <div className="container mx-auto px-4 max-h-48">
                <div className="flex items-center justify-between">
                    <div className="flex items-center h-[120px] relative text-center mt-6">
                        <img src="/assets/logo.png" alt='gürbüz-avukatlık-logo' width={200} height={80} className='object-contain' />
                    </div>
                    <div className="hidden md:flex items-center space-x-6 text-xl">
                        <NavLink href="#hero">Anasayfa</NavLink>
                        <p className='text-white'>|</p>
                        <NavLink href="#about-us">Hakkımızda</NavLink>
                        <p className='text-white'>|</p>
                        <NavLink href="#practice-area">Çalışma Alanlarımız</NavLink>
                        <p className='text-white'>|</p>
                        <NavLink href="#team">Ekibimiz</NavLink>
                        <p className='text-white'>|</p>
                        <NavLink href="#news">Makaleler</NavLink>
                        <p className='text-white'>|</p>
                        <NavLink href="#contact">İletişim</NavLink>
                        <div className="flex items-center space-x-4">
                            <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 18a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" />
                            </svg>

                            <input
                                type="text"
                                placeholder="Ara..."
                                className="bg-transparent border-b-2 border-white text-white focus:outline-none"
                            />
                        </div>
                    </div>
                    <div className="md:hidden">
                        <button onClick={toggleMenu} className="focus:outline-none">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`md:hidden bg-[#16695b] transition-all duration-300 ${isOpen ? "max-h-60" : "max-h-0"} overflow-hidden`}>
                <div className="px-4 py-2">
                    <NavLink href="#hero" mobile>Anasayfa</NavLink>
                    <NavLink href="#about-us" mobile>Hakkımızda</NavLink>
                    <NavLink href="#practice-area" mobile>Çalışma Alanlarımız</NavLink>
                    <NavLink href="#team" mobile>Ekibimiz</NavLink>
                    <NavLink href="#news" mobile>Makaleler&Haberler</NavLink>
                    <NavLink href="#contact" mobile>İletişim</NavLink>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;