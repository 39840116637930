import { FC } from "react";

const NavLink: FC<{ href: string; children: React.ReactNode; mobile?: boolean }> = ({ href, children, mobile }: any) => (
    <a href={href}>
        <span className={`text-white hover:text-[#f1c40f] transition-colors ${mobile ? 'block py-2' : 'inline-block'}`}>
            {children}
        </span>
    </a>
);


export default NavLink