// src/components/Footer.tsx
import { FC } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const Footer: FC = () => {
    return (
        <footer className="bg-[#122417] text-white py-12 mt-12 border-t border-gray-700">
            <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between">
                <div className="md:w-1/3 mb-8 md:mb-0">
                <div className="flex items-center h-[80px] relative text-center mt-6">
                        <img src="/assets/logo.png" alt='gürbüz-avukatlık-logo' width={150} height={40} className='object-contain'/>
                    </div>
                    <div className="flex space-x-6 mt-12">
                        <a href="#" className="text-white text-2xl hover:text-gray-400">
                            <FaFacebookF />
                        </a>
                        <a href="#" className="text-white text-2xl hover:text-gray-400">
                            <FaTwitter />
                        </a>
                        <a href="#" className="text-white text-2xl hover:text-gray-400">
                            <FaLinkedinIn />
                        </a>
                        <a href="#" className="text-white text-2xl hover:text-gray-400">
                            <FaInstagram />
                        </a>
                    </div>
                </div>
                <div className="md:w-1/3 mb-8 md:mb-0">
                    <h3 className="text-xl font-bold mb-4">Hızlı Ulaşım</h3>
                    <ul className="space-y-2">
                        <li><a href="#hero" className="hover:text-gray-400">Anasayfa</a></li>
                        <li><a href="#about-us" className="hover:text-gray-400">Hakkımızda</a></li>
                        <li><a href="#practice-area" className="hover:text-gray-400">Çalışma Alanlarımız</a></li>
                        <li><a href="#team" className="hover:text-gray-400">Ekibimiz</a></li>
                        <li><a href="#news" className="hover:text-gray-400">Makaleler&Haberler</a></li>
                        <li><a href="#contact" className="hover:text-gray-400">İletişim</a></li>
                    </ul>
                </div>
                <div className="md:w-1/3 mb-8 md:mb-0">
                    <h3 className="text-xl font-bold mb-4">Çalışma Alanları</h3>
                    <ul className="space-y-2">
                        <li><a href="#practice-area" className="hover:text-gray-400">Şirketler Hukuku</a></li>
                        <li><a href="#practice-area" className="hover:text-gray-400">İş Hukuku</a></li>
                        <li><a href="#practice-area" className="hover:text-gray-400">Aile Hukuku</a></li>
                        <li><a href="#practice-area" className="hover:text-gray-400">Sigorta Hukuku</a></li>
                        <li><a href="#practice-area" className="hover:text-gray-400">Miras Hukuku</a></li>
                        <li><a href="#practice-area" className="hover:text-gray-400">Ceza Hukuku</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
